import React, { createContext, useState } from 'react';

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive
  );

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
